import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Meta from "../components/Meta";
import "../assets/styles/StoreFrontScreen.css"; // Import custom CSS

const StoreFrontScreen = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <Meta title="Store Front | Lugo Parts & Restoration" />
      <Container className="store-front-container mt-5 mb-5">
        <Row className="mb-4">
          <Col>
            <h2 className="text-center mb-5">
              Explore, Discover, Restore: Navigate Your Restoration Adventure
              with Our Premium Parts
            </h2>
            <p className="landing-page-text text-center mb-3">
              Welcome to our exclusive stores where we regularly add exciting
              new items. Dive into our collections to find the perfect parts for
              your restoration projects.
            </p>
          </Col>
        </Row>
        <Row>
          {/* Box 1: New Aftermarket Parts */}
          <Col xs={12} md={6} lg={6} className="mb-3">
            <Card
              className="store-card h-100"
              onClick={() => handleNavigation("/store")}
            >
              {/* <div className="discount-banner">10% OFF</div> */}
              <Card.Img
                variant="top"
                src="/images/compressed/webp/1097T.webp"
              />
              <Card.Body>
                <Card.Title>New Aftermarket Parts</Card.Title>
                <Card.Text className="store-card-text">
                  Discover a vast selection of high-quality aftermarket parts to
                  enhance your vehicle's performance and aesthetics. New items
                  added regularly.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Box 2: New NAPCO Parts */}
          <Col xs={12} md={6} lg={6} className="mb-3">
            <Card
              className="store-card h-100"
              onClick={() => handleNavigation("/napco-store")}
            >
              {/* <div className="discount-banner">25% OFF</div> */}
              <Card.Img
                variant="top"
                src="/images/compressed/webp/NAPCOnewest.webp"
              />
              <Card.Body>
                <Card.Title>New NAPCO Parts</Card.Title>
                <Card.Text className="store-card-text">
                  Explore our exclusive collection of New NAPCO Parts, all made
                  in the USA, guaranteeing unparalleled quality and
                  authenticity.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Box 3: Coming Soon: Used Parts */}
          <Col xs={12} md={6} lg={6} className="mb-3">
            <Card
              className="store-card h-100"
              onClick={() =>
                window.open(
                  "https://www.ebay.com/str/lugopartsrestoration",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              <Card.Img
                variant="top"
                src="/images/compressed/webp/usedParts1.webp"
              />
              <Card.Body>
                <Card.Title>Used Parts</Card.Title>
                <Card.Text className="store-card-text">
                  Now open! Our store offers a wide selection of used parts for
                  classic GMC and Chevrolet trucks, providing a cost-effective
                  solution for your restoration needs with assured quality.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StoreFrontScreen;
