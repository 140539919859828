import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Meta from "../../components/Meta";
import { toast } from "react-toastify";
import {
  useGetCouponsQuery,
  useCreateCouponMutation,
  useDeleteCouponMutation,
} from "../../slices/couponsApiSlice";

const CouponListScreen = () => {
  const navigate = useNavigate();
  const { pageNumber } = useParams();
  const { data, isLoading, error } = useGetCouponsQuery({
    pageNumber,
  });

  const [createCoupon, { isLoading: isLoadingCreate }] =
    useCreateCouponMutation();
  const [deleteCoupon] = useDeleteCouponMutation();

  const createHandler = async () => {
    try {
      const response = await createCoupon().unwrap();
      navigate(`/admin/couponlist/${response._id}/edit`);
      toast.success("New coupon created.");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure?")) {
      try {
        await deleteCoupon(id).unwrap();
        toast.success("Coupon deleted");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>
      <Meta
        title="Coupon List | Admin | Lugo Parts & Restoration"
        description="Admin panel for managing coupons at Lugo Parts & Restoration."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>
      <Row className="align-items-center">
        <Col>
          <h1>Coupons</h1>
        </Col>
        <Col className="text-end">
          <Button className="btn-sm my-3" onClick={createHandler}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <FaPlus /> &nbsp;Create Coupon
            </span>
          </Button>
        </Col>
      </Row>
      {isLoadingCreate && <Loader />} {/* Show loader when creating a coupon */}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error.data.message}</Message>
      ) : (
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>COUPON ID</th>
              <th>CODE</th>
              <th>NAPCO DISCOUNT</th>
              <th>AFTERMARKET DISCOUNT</th>
              <th>STATUS</th>
              <th>EDIT/DELETE</th>
            </tr>
          </thead>
          <tbody>
            {data.coupon?.map((coupon) => (
              <tr key={coupon._id}>
                <td>{coupon._id}</td>
                <td>{coupon.code}</td>
                <td>{coupon.napcoDiscountPercentage}%</td>
                <td>{coupon.aftermarketDiscountPercentage}%</td>
                <td>{coupon.isActive ? "Active" : "Inactive"}</td>
                <td>
                  <Button
                    variant="light"
                    className="btn-sm mx-2"
                    onClick={() =>
                      navigate(`/admin/couponlist/${coupon._id}/edit`)
                    }
                  >
                    <FaEdit />
                  </Button>
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(coupon._id)}
                  >
                    <FaTrash style={{ color: "white" }} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default CouponListScreen;
