import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BackToTopButton from "./components/BackToTopButton";
// import SaleBanner from "./components/SaleBanner";
import ReactGA from "react-ga4";
// Roboto for materialUI
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const GA4MEASUREMENTID = "G-DTJ60834VZ";
ReactGA.initialize(GA4MEASUREMENTID);

const App = () => {
  const location = useLocation();
  let currentPath = location.pathname;

  if (currentPath.includes("/store")) {
    currentPath = "/store";
  }

  const specialPages = [
    "/",
    "/restoration",
    "/parts",
    "/gallery",
    "/about",
    "/career",
  ]; // Add other routes here that should have the same effect as the LandingPage

  const isSpecialPage =
    specialPages.includes(location.pathname) ||
    location.pathname.startsWith("/store");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: document.title,
    });
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header key={currentPath} />
      {/* <SaleBanner /> */}
      <main className={isSpecialPage ? "" : "py-3"}>
        {isSpecialPage ? (
          <>
            <Outlet />
          </>
        ) : (
          <Container>
            <Outlet />
          </Container>
        )}
      </main>
      <Footer />
      <ToastContainer />
      {/* {location.pathname.startsWith("/store") && <BackToTopButton />} */}
      <BackToTopButton />
    </>
  );
};

export default App;
